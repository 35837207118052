import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = [ "bookmarkSellerId", "bookmarkProductId", "modalTitle" ]

  setParamId(e) {
    const button = event.relatedTarget // Button that triggered the modal
    const sellerId = button.getAttribute('data-seller-id') // Extract seller ID
    const productId = button.getAttribute('data-product-id') // Extract product ID

    if (sellerId) {
      this.bookmarkSellerIdTarget.value = sellerId
    }

    if (productId) {
      this.bookmarkProductIdTarget.value = productId
    }

    if (!window.isMobile) {
      this.element.querySelector(".form-control").focus()
    }
  }

  setModalTitle(e) {
    const button = event.relatedTarget // Button that triggered the modal
    const modalTitle = button.getAttribute("data-title") // Exctract modal title

    if (modalTitle) {
      this.modalTitleTarget.innerHTML = modalTitle
    }
  }

  resetForm(e) {
    this.element.querySelector("form").reset()
    this.element.querySelector("form").classList.remove("was-validated")
  }

  closeModal(e) {
    e.preventDefault()

    let modal = Modal.getOrCreateInstance(this.element)
    modal.hide()

    setTimeout(() => {
      Turbo.visit("/unete")
    }, 300)
  }
}
