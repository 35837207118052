import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    userId: Number
  }
  static targets = ["price", "visits", "link"]

  initialize() {
    let url = window.location.search
    const urlParams = new URLSearchParams(url)

    if (this.userIdValue == 99) {
      this.priceTarget.setAttribute("style", "display: none !important;")
    }

    if (urlParams.has("p")) {
      this.priceTarget.setAttribute("style", "display: none !important;")
      this.visitsTarget.setAttribute("style", "display: none !important;")

      this.linkTargets.forEach((link) => {
        let href = link.getAttribute("href")
        let hrefParams = new URLSearchParams(href.split('?')[1])

        if (!hrefParams.has("p")) {
          link.setAttribute("href", `${href}?p=${urlParams.get("p")}`)
        }
      })
    }
  }
}
