import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "emailFeedback" ]

  handleSubmit(e) {
    if (!this.validateForm()) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }

    this.element.requestSubmit()
  }

  validateForm() {
    this.element.classList.add('was-validated')

    if (!this.element.checkValidity()) {
      this.element.querySelectorAll(':invalid')[0].focus()
      return false
    }

    if (this.element.querySelectorAll('.is-invalid').length) {
      this.element.querySelectorAll('.is-invalid')[0].focus()
      return false
    }

    return true
  }

  validateEmail(e) {
    const value = e.currentTarget.value.trim()

    Rails.ajax({
      type: "POST",
      url: '/users/validate_email',
      data: new URLSearchParams({ email: value }).toString(),
      success: (response) => {
        if (!response.valid) {
          e.target.classList.add('is-invalid')
          this.emailFeedbackTarget.textContent = 'Este correo ya está registrado.'
        } else {
          e.target.classList.remove('is-invalid')
          this.emailFeedbackTarget.textContent = 'Por favor ingresa un correo válido.'
        }
      }
    })
  }
}
