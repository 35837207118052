import { Fancybox } from "@fancyapps/ui"

document.addEventListener("turbo:load", (e) => {
  trix.init()
  fancybox.init()

  window.isMobile = window.matchMedia("only screen and (max-width: 991px)").matches
})

const fancybox = {
  init: () => {
    Fancybox.destroy()
    Fancybox.bind("[data-fancybox]")
    Fancybox.bind('[data-fancybox="gallery"]', {
      animated: false,
      showClass: false,
      hideClass: false,
      dragToClose: false,

      Image: {
        zoom: false,
      },

      Toolbar: {
        display: [{ id: "counter", position: "center" }, "close"],
      },
      on: {
        "Carousel.ready Carousel.change": (fancybox) => {
          const slide = fancybox.getSlide()

          let videoUrl = slide.src
          if (!videoUrl.endsWith(".mp4")) return

          let video = document.createElement("video")
          video.src = videoUrl
          video.preload = "metadata"

          video.onloadedmetadata = () => {
            let width = video.videoWidth
            let height = video.videoHeight

            if (height > width) {
              slide.$el.querySelector('.fancybox__content').style.height = "80vh"
            }
          }
        }
      }
    })
  }
}

const trix = {
  init: () => {
    window.addEventListener("trix-file-accept", (event) => {
      event.preventDefault()
      alert("Agregar archivos no está habilitado.")
    })
  }
}
